<template>
    <v-layout wrap>
        <v-flex xs12 class="d-flex justify-space-between">
            <span class="display-1 px-5 pb-5 font-weight-medium"
                >Materiale ședințe pentru Consilierii Locali</span
            >
            <v-btn
                @click.stop="dialog = true"
                color="secondary"
                v-if="hasPermission"
                >Ședință Nouă</v-btn
            >
        </v-flex>
        <v-flex xs12 ml-5>
            <v-data-table
                :items="data.items"
                :options.sync="data.pagination"
                :server-items-length="data.total"
                @update:options="getData"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': $vuetifyTableItemsPerPage
                }"
                :headers="headers"
            >
                <template v-slot:no-data>
                    <v-alert type="info">Nici un rezultat.</v-alert>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-flex>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="prepareForUpdate(item)"
                                    color="primary"
                                    icon
                                    v-on="on"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editează Ședința {{ item.name }}</span>
                        </v-tooltip>
                        <v-tooltip top v-if="hasPermission">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="deleteData(item)"
                                    color="error"
                                    icon
                                    v-on="on"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Sterge Ședința {{ item.name }}</span>
                        </v-tooltip>
                    </v-flex>
                </template>
            </v-data-table>
        </v-flex>
        <v-dialog
            @keydown.esc="dialog = false"
            fullscreen
            persistent
            v-model="dialog"
        >
            <v-card tile>
                <v-card-title
                    class="headline primary white--text"
                    primary-title
                >
                    {{ createDialogTitle }}
                    <v-spacer></v-spacer>
                    <v-btn @click="resetForms">Închide</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout v-if="hasPermission">
                            <v-flex xs12>
                                <v-text-field
                                    :error-messages="errors.name"
                                    @input="errors.name = []"
                                    label="Nume"
                                    prepend-icon="mdi-account-supervisor-circle"
                                    v-model="newData.name"
                                ></v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-btn @click="saveOrUpdateData" color="primary"
                                    >Salvează</v-btn
                                >
                            </v-flex>
                        </v-layout>
                        <v-layout v-if="editMode">
                            <v-flex>
                                <v-sheet class="pa-4 primary">
                                    <v-text-field
                                        v-model="search"
                                        label="Caută după nume"
                                        dark
                                        flat
                                        solo-inverted
                                        hide-details
                                        clearable
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                    <v-checkbox
                                        v-model="caseSensitive"
                                        dark
                                        class="mb-2"
                                        hide-details
                                        label="Căutare sensibilă la majuscule"
                                    ></v-checkbox>
                                    <v-treeview
                                        v-model="tree"
                                        dark
                                        :items="[newData.folder]"
                                        activatable
                                        :search="search"
                                        :filter="filter"
                                        selectable
                                        selection-type="independent"
                                        return-object
                                        item-text="name"
                                        selected-color="secondary"
                                        item-key="id"
                                        open-on-click
                                        @input="selectNode"
                                    >
                                        <template
                                            v-slot:prepend="{ item, open }"
                                        >
                                            <v-icon v-if="!item.mime_type">
                                                {{
                                                    open
                                                        ? "mdi-folder-open"
                                                        : "mdi-folder"
                                                }}
                                            </v-icon>
                                            <v-icon v-else>
                                                {{ getIcon(item.mime_type) }}
                                            </v-icon>
                                        </template>
                                        <template v-slot:label="{ item }">
                                            <span v-if="!item.original_url">
                                                {{ item.name }}
                                            </span>
                                            <a
                                                :href="item.original_url"
                                                target="_blank"
                                                v-else
                                                ref="nofollow"
                                                style="color:white"
                                            >
                                                {{ item.file_name }}
                                            </a>
                                        </template>
                                    </v-treeview>
                                </v-sheet>
                            </v-flex>
                            <v-flex v-if="hasPermission">
                                <template v-if="tree.length === 0">
                                    <v-alert type="info"
                                        >Selectați un folder</v-alert
                                    >
                                </template>
                                <template v-if="tree.length > 1">
                                    <v-alert type="info"
                                        >Selectați un singur folder</v-alert
                                    >
                                </template>
                                <template v-if="tree.length === 1">
                                    <v-layout wrap>
                                        <v-flex xs12>
                                            <v-layout align-center>
                                                <v-flex>
                                                    <v-text-field
                                                        :error-messages="
                                                            errors.name
                                                        "
                                                        @input="
                                                            errors.name = []
                                                        "
                                                        label="Nume"
                                                        prepend-icon="mdi-account-supervisor-circle"
                                                        v-model="
                                                            folderData.name
                                                        "
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex>
                                                    <v-btn
                                                        @click="addFolder"
                                                        color="primary"
                                                        small
                                                        >Adaugă Folder în
                                                        {{
                                                            selectedNode.name
                                                        }}</v-btn
                                                    >
                                                </v-flex>
                                            </v-layout>
                                            <v-layout align-center>
                                                <v-flex>
                                                    <v-text-field
                                                        :error-messages="
                                                            errors.name
                                                        "
                                                        @input="
                                                            errors.name = []
                                                        "
                                                        label="Nume"
                                                        prepend-icon="mdi-account-supervisor-circle"
                                                        v-model="
                                                            selectedNode.name
                                                        "
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex>
                                                    <v-btn
                                                        @click="updateFolder"
                                                        color="primary"
                                                        small
                                                        >Schimbă Nume
                                                        Folder</v-btn
                                                    >
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex>
                                            <vue-dropzone
                                                includeStyling
                                                duplicateCheck
                                                ref="myVueDropzone"
                                                id="dropzone"
                                                @vdropzone-queue-complete="
                                                    uploadFinished
                                                "
                                                :options="dropzoneOptions"
                                            ></vue-dropzone>
                                        </v-flex>
                                    </v-layout>
                                </template>
                                <template v-if="tree.length">
                                    <v-btn
                                        color="error"
                                        large
                                        @click="deleteSelection"
                                        >Șterge selecția</v-btn
                                    >
                                    <v-alert type="warning" class="mt-4"
                                        >Atenție, la apăsarea butonului de
                                        ștergere se va șterge si interiorul
                                        folderului</v-alert
                                    >
                                    <v-alert type="warning" class="mt-1"
                                        >Nu ștergeți folderul Fișiere!</v-alert
                                    >
                                </template>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import hasPermission from "../mixins/hasPermission";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
    name: "Meetings",
    metaInfo: {
        title: "Sedinte -"
    },
    mixins: [hasPermission],
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            data: {},
            createDialogTitle: "Creează Ședință Nouă",
            loading: false,
            newData: {},
            folderData: {},
            editMode: false,
            search: null,
            errors: {},
            dialog: false,
            pagination: {},
            selectedNode: {},
            tree: [],
            dropzoneOptions: {
                url: "https://gherla.mol-uat.ro/api/meetings/files",
                maxFilesize: 100,
                createImageThumbnails: false,
                withCredentials: true,
                headers: { Authorization: "Bearer " + this.$auth.token() }
            },
            caseSensitive: false,
            headers: [
                { text: "Nume", align: "left", sortable: false, value: "name" },
                {
                    text: "Acțiuni",
                    align: "right",
                    sortable: false,
                    value: "actions"
                }
            ]
        };
    },
    computed: {
        filter() {
            return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined;
        }
    },
    methods: {
        getIcon(mime) {
            if (mime.includes("pdf")) {
                return "mdi-file-pdf";
            }
            if (mime.includes("image")) {
                return "mdi-file-image";
            }

            return "mdi-file-document-outline";
        },
        selectNode(event) {
            if (event && event.length === 1) {
                this.selectedNode = event[0];
                this.dropzoneOptions.url =
                    "https://gherla.mol-uat.ro/api/meetings/" +
                    this.newData.id +
                    "/files/" +
                    this.selectedNode.id;
            }
        },
        async uploadFinished() {
            const { data } = await this.axios.get(
                "/meetings/" + this.newData.id
            );

            this.newData = { ...data };
        },
        async getData() {
            this.loading = true;
            let response = await this.axios.get("/meetings", {
                params: this.data.pagination
            });
            this.loading = false;
            this.data = response.data;
        },
        resetForms() {
            this.dialog = false;
            this.newData = {};
            this.createDialogTitle = "Creează Ședință Nouă";
            this.editMode = false;
            this.errors = [];
        },
        async addFolder() {
            try {
                const { data } = await this.axios.post("/folders", {
                    ...this.folderData,
                    folder_id: this.selectedNode.id,
                    meeting_id: this.newData.id
                });
                this.folderData = {};
                this.newData = { ...data };
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        async updateFolder() {
            try {
                const { data } = await this.axios.post(
                    "/folders/" + this.selectedNode.id,
                    {
                        ...this.selectedNode,
                        meeting_id: this.newData.id,
                        _method: "PATCH"
                    }
                );
                this.folderData = {};
                this.newData = { ...data };
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        async deleteData(item) {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că doriți să ștergeți ședința?"
            });
            if (res) {
                try {
                    const response = await this.axios.post(
                        "/meetings/" + item.id,
                        {
                            _method: "DELETE"
                        }
                    );
                    this.$eventBus.$emit("notify", response.data.message);
                    const index = this.data.items.indexOf(item);
                    this.data.items.splice(index, 1);
                } catch ({ response }) {
                    this.$eventBus.$emit(
                        "notify",
                        response.data.message,
                        "error"
                    );
                }
            }
        },
        async deleteSelection() {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că doriți să ștergeți selecția?"
            });
            if (res) {
                try {
                    const response = await this.axios.post(
                        "/folders/delete/" + this.newData.id,
                        {
                            data: this.tree
                        }
                    );

                    this.newData = { ...response.data };
                } catch ({ response }) {
                    this.$eventBus.$emit(
                        "notify",
                        response.data.message,
                        "error"
                    );
                }
            }
        },
        async updateData() {
            try {
                const { data } = await this.axios.post(
                    "/meetings/" + this.newData.id,
                    {
                        _method: "PATCH",
                        ...this.newData
                    }
                );
                this.$eventBus.$emit("notify", data.message);
                this.resetForms();
                await this.getData();
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        prepareForUpdate(item) {
            this.createDialogTitle = "Editează Ședința" + ` „${item.name}”`;
            this.newData = JSON.parse(JSON.stringify(item));
            this.newData.index = this.data.items.indexOf(item);
            this.editMode = true;
            this.dialog = true;
        },
        async saveData() {
            try {
                const { data } = await this.axios.post(
                    "/meetings",
                    this.newData
                );
                this.$eventBus.$emit("notify", data.message);
                this.resetForms();
                await this.getData();
            } catch ({ response }) {
                this.$eventBus.$emit("notify", response.data.message, "error");
                this.errors = response.data.errors;
            }
        },
        saveOrUpdateData() {
            if (this.editMode) {
                this.updateData();
                return;
            }

            this.saveData();
        }
    }
};
</script>
